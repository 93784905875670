<!--
 * @Author: xiaziwen
 * @Date: 2021-01-25 17:13:15
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-25 17:32:29
 * @FilePath: \crm-education\src\views\protal\news\detail.vue
-->
<template>
  <div class="protal-detail-wrap">
    <div class="sider-menu">
      <a-menu mode="inline" v-model="subMenuSelectKeys" @click="onSubMenuClick">
        <a-menu-item v-for="menu in subMenuList" :key="menu.columnsTypeCode">
          {{ menu.columnsName }}
        </a-menu-item>
      </a-menu>
    </div>
    <div class="protal-news-detail">
      <div class="page-title py-20">
        <p class="color-gray fs-14">
          首页><router-link :to="`/protal/list?columnsTypeCode=${currentColumns.columnsTypeCode}`">{{
            currentColumns.columnsName
          }}</router-link
          >><span class="color-black">{{ currentInfo.informationTitle }}</span>
        </p>
      </div>
      <p class="fs-20 py-10 text-center" v-if="currentInfo.informationShowModel == 1">
        {{ currentInfo.informationTitle }}
      </p>
      <div class="py-10" v-if="currentInfo.createTime && currentInfo.createTime.length > 0">
        <p class="fs-14 color-gray text-center">
          发布时间：{{ currentInfo.createTime[0] }}-{{ currentInfo.createTime[1] }}-{{ currentInfo.createTime[2] }}
        </p>
      </div>
      <div class="py-10 fs-16">
        <div class="article-text" v-html="currentInfo.infromationContent"></div>
      </div>
      <div class="annex py-10 fs-16 d-flex" v-if="currentInfo.resourceUrl">
        <p class="label">附件：</p>
        <a :href="currentInfo.resourceUrl" target="_blank">{{ currentInfo.resourceName }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api'
import { getCurrentByCode, getCurrentByColumnsId, getSubMenuByCode } from '@/utils/protal'

export default {
  name: 'protal_list_detail',
  data() {
    return {
      columnsId: '',
      informationId: '',
      info: [],
      currentColumn: {},
      subMenuList: [],
      subMenuSelectKeys: []
    }
  },
  computed: {
    ...mapState(['columnsInfo']),
    currentInfo() {
      const current = this.info.find(item => {
        return String(item.informationId) === String(this.informationId)
      })
      return current || {}
    },
    currentColumns() {
      const current = this.columnsInfo.find(item => {
        return String(item.columnsId) === String(this.columnsId)
      })
      return current || {}
    }
  },
  watch: {
    $route: {
      handler(nv, ov) {
        this.init()
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const { columnsId, informationId, columnsTypeCode } = this.$route.query
      this.currentColumn = getCurrentByCode(this.columnsInfo, columnsTypeCode)
      this.subMenuList = getSubMenuByCode(this.columnsInfo, columnsTypeCode)
      this.subMenuSelectKeys = [this.currentColumn.columnsTypeCode]
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnsId
      })
      if (res.code === 200) {
        this.info = res.data
        this.informationId = informationId
        this.columnsId = columnsId
      }
    },
    async onSubMenuClick(menu) {
      const current = getCurrentByCode(this.columnsInfo, menu.key)
      if (menu.key !== '/protal') {
        let path = '/protal/list'
        if (current.columnsShowModel == 1) {
          path = '/protal/list'
          this.$router.push({
            path: path,
            query: { columnsTypeCode: menu.keyPath[0] }
          })
        } else {
          path = '/protal/list/detail'
          let res = await api.protal.getInfoByColumnId({
            columnsId: current.columnsId
          })
          if (res.code === 200 && res.data.length === 1) {
            this.$router.push({
              path: path,
              query: {
                columnsTypeCode: menu.keyPath[0],
                columnsId: current.columnsId,
                informationId: res.data[0].informationId
              }
            })
          } else {
            this.$router.push({
              path: path,
              query: {
                columnsTypeCode: menu.keyPath[0],
                columnsId: current.columnsId
              }
            })
          }
        }
      } else {
        this.$router.push({
          path: '/protal'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.protal-detail-wrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.protal-news-detail {
  flex: 1;
  .cover-img {
    border: 1px solid #eee;
  }
  .article-text {
    padding: 0 20px;
  }
}
.sider-menu {
  width: 240px;
  height: min-content;
  margin: 72px 20px 20px 0;
  border: 1px solid #eee;
  ::v-deep .ant-menu-item::after {
    border-right: 3px solid #fa3f3f;
  }
  ::v-deep .ant-menu-item.ant-menu-item-selected {
    background-color: #fa3f3f;
    color: #fff;
  }
  ::v-deep .ant-menu-item.ant-menu-item-selected:hover {
    color: #fff;
  }
  ::v-deep .ant-menu-item:hover {
    color: #fa3f3f;
  }
  ::v-deep .ant-menu-inline {
    border: none;
    li {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.annex {
  .label {
  }
}
@media screen and (max-width: 768px) {
  .sider-menu {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
</style>
